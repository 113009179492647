import {InstalledAppsState} from '../types/state'

interface StateWithInstalledAppsState {
  installedApps: InstalledAppsState
}

export const isEventsInMembersInstalled = (state: StateWithInstalledAppsState) =>
  state.installedApps.eventsInMembersInstalled

export const isMembersInstalled = (state: StateWithInstalledAppsState) => state.installedApps.members

export const isPaidPlansInstalled = (state: StateWithInstalledAppsState) => state.installedApps.paidPlans

export const isSchedulePageInstalled = (state: StateWithInstalledAppsState) => state.installedApps.schedule

export const isGroupsInstalled = (state: StateWithInstalledAppsState) => state.installedApps.groups

export const isGiftCardsInstalled = (state: StateWithInstalledAppsState) => state.installedApps.giftCards
